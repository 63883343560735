.navbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 80px;
    padding: 0;
}

.navbar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    padding: 10px 20px;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
}

.navbar-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 100%;
    background-color: #fff;
    transform: skewX(-20deg);
    z-index: -1;
}

.navbar-logo .logo {
    margin-left: 20px;
    height: 55px;
}

.navbar-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
}

.navbar-date {
    font-weight: bold;
    margin-right: 20px;
}

.navbar-icons {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
}

.icon {
    width: 28px;
    height: 28px;
}

.user-name {
    font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
}

@media (max-width: 768px) {
    .navbar-top {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        justify-content: space-between;
    }

    .navbar-logo .logo {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .navbar-content {
        margin-left: 0;
        margin-right: 0;
        justify-content: flex-end;
    }

    .navbar-date {
        margin-right: 10px;
    }

    .navbar-icons {
        gap: 10px;
    }

    .user-name {
        margin-right: 5px;
    }
}

.navbar-date,
.user-name {
    font-weight: bold;
}

.navbar-bottom-green {
    background-color: #5C9A41;
    border-radius: 20px 20px 0 0;
    height: 100px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    margin-top: -15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navbar-bottom-green .nav-button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 0px 10px 20px 30px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.nav-button {
    background-color: white;
    color: black;
    border: none;
    padding: 8px 16px;
    margin: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
}

.nav-button.active {
    background-color: green;
    color: white;
}

.nav-icon {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.navbar-bottom-green .nav-button.active {
    background-color: white;
    color: #5C9A41;
    border-radius: 30px;
}

.navbar-bottom-green .nav-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .navbar-bottom-green {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0;
    }

    .navbar-bottom-green .nav-button {
        margin: 5px 20px;
        text-align: center;
    }
}

.content {
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    height: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    margin-top: -25px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 80vh;
    padding: 40px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .content {
        padding: 20px;
        margin-top: -20px;
        min-height: auto;
    }
}

.notification-modal {
    position: absolute;
    top: 50px;
    right: 20px;
    width: 250px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    overflow: hidden;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.modal-body {
    padding: 10px;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.modal-footer {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #f5f5f5;
}

.clear-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.clear-button:hover {
    background-color: #e60000;
}

.dropdown{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
}

.dropdown-icon {
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.dropdown-menu {
    position: relative;
    right: 0;
    max-width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.dropdown-option {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.dropdown-option .dropdown-icon {
    margin-right: 10px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.appointment-summary {
    background-color: #fff;
    /* Faded color border */
    border: 2px solid #00000014;
    border-radius: 8px;
    padding: 20px;
}

.summary-box {
    border-right: 1px solid #00000030;
    background-color: #fff;
    display: flex;
}

.summary-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.summary-text {
    display: flex;
    align-items: center;
    gap: 6px;
}

.summary-text p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.summary-text h5 {
    margin: 0;
    font-size: 12px;
    font-weight: normal;
    color: #00000070;
}

@media (max-width: 767px) {
    .summary-box {
        margin-bottom: 5px;
        border-right: none;
        border-bottom: 1px solid #00000030;
    }
}

/* Adjust padding and margin for larger screens */
@media (min-width: 768px) {
    .summary-box {
        margin-bottom: 0;
        border-right: 1px solid #00000030;
        border-bottom: none;
    }
}

.my-schedule-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    /* Adds space above the section */
}

/* Styling for the My Schedule text */
.my-schedule-text {
    font-size: 24px;
    /* Adjust font size as needed */
    margin-right: 20px;
    /* Space between the text and the button */
}

/* Add Schedule button styles */
.add-schedule-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.add-schedule-button {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    border-radius: 8px;
    background-color: #5C9A41;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 0px;
}

.add-schedule-button:hover {
    background-color: #5ca941;
    color: #fff;
}

.add-icon {
    margin-right: 10px;
    /* Adjusted margin to align with text */
    border-radius: 50%;
    background-color: #FFFFFF40;
    /* Faded green background */
    color: #fff;
    /* White color for icon */
    font-size: 24px;
    padding: 4px;
}

.add-icon:hover {
    background-color: #FFFFFF40;
    color: #fff;
}

/* Date Slider Container */
.date-slider {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

/* Slider Arrows */
.slider-arrow {
    font-size: 24px;
    color: #000;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-right: 20px;
}

.slider-arrow:hover {
    color: #FF9D1A;
}

/* Date Container */
.date-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

/* Date Items */
.date-item {
    /* width: 12%; */
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 10px;
    margin: 0 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.selected {
    background-color: #FF9D1A;
    /* Highlighted date color */
    color: #fff;
    /* Text color for selected date */
}

.unselected {
    background-color: #EBEBEB;
    /* Unselected date color */
    color: #000;
    /* Text color for unselected dates */
}

.scroll-horizontal {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
}

.scroll-horizontal::-webkit-scrollbar {
    display: none;
}

.scroll-vertical::-webkit-scrollbar {
    display: none;
}

h1.headingDate {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    margin-bottom: 0px;
    color: #000000;
}

.topHeading {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;

    color: #000000;


}

.scroll-vertical {
    display: flex;
    gap: 10px;
    flex-direction: column;
    overflow-y: scroll;
}

.toggleBtn.active {
    background: #5C9A41;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggleBtn {
    background: #5C9A4170;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .date-picker {
    border: 1px solid #ccc;
    border-radius: 50px;
    padding: 0.5rem;
    width: 200px;
    margin-left: 1rem;
  } */

.date-picker-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.date-picker {
    border: 1px solid #ccc;
    /* Adjust border style if needed */
    border-radius: 50px !important;
    padding: 10px !important;
    cursor: pointer;
}

.calendar-icon {
    position: absolute;
    top: 44%;
    right: 20px;
    /* Adjust position to fit within the border */
    transform: translateY(-50%);
    width: 24px;
    height: auto;
    cursor: pointer;
}

.react-datepicker__day--selected {
    background-color: #5ca941 !important;
    /* Customize selected date background color */
    color: #fff !important;
    /* Customize selected date text color */
}

.circular-image {
    width: 40px;
    /* Adjust size as needed */
    height: 40px;
    /* Adjust size as needed */
    border-radius: 50%;
    object-fit: cover;
    /* Ensures the image fits within the circle */
    margin-right: 15px;
    /* Consistent spacing with other elements */
}

.filterBtn {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    transition: background-color 0.3s;
    background-color: #EBEBEB;
}

.filterBtn.active-filter {
    background-color: #FF9D1A;
    color: #fff;
}

.clearfilterBtn {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    background-color: #fff;
    color: #fff;
    transition: background-color 0.3s;
    background-color: #FF9D1A;
}

.toggleBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.toggleBtn img.mediumIcon {
    width: 25px;
    height: 25px;
}

.search-bar-container {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 5px;
    max-width: 250px;
    /* Adjust width as needed */
    height: auto;
}

.search-input {
    position: relative;
    display: inline-block;
    border: none;
    outline: none;
    padding: 0px !important;
    width: 100%;
    height: auto;
    border-radius: 5px;
    font-size: 16px;
}

.search-button {
    background: transparent;
    color: #999999;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin: 0px !important;
}

.search-button .fa-search {
    color: #333;
    font-size: 18px;
}

.PaymentTable {
    margin-top: 20px;
    overflow-x: auto;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 10px;

}

.PaymentTable .table {
    width: 100%;
    border-collapse: collapse;
}

.PaymentTable .table th,
.PaymentTable .table td {
    padding: 10px;
    text-align: center;
}

.PaymentTable .table thead {
    border-top: none;
}

.PaymentTable .status-box {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    padding: 5px 10px;
    color: #000000ae;
}

.PaymentTable .status-paid {
    background-color: #5C9A41cf;
}

.PaymentTable .status-processing {
    background-color: #dcb02caf;
}

.PaymentTable .status-unpaid {
    background-color: #ff4c4caf;
}

@media (max-width: 768px) {
    .PaymentTable .table {
        font-size: 14px;
    }

    .PaymentTable .table th,
    .PaymentTable .table td {
        padding: 8px;
    }
}

@media (max-width: 576px) {
    .PaymentTable .table {
        font-size: 12px;
    }

    .PaymentTable .table th,
    .PaymentTable .table td {
        padding: 6px;
        display: block;
        text-align: left;
    }

    .PaymentTable .table thead {
        display: none;
    }

    .PaymentTable .table tr {
        margin-bottom: 15px;
        display: block;
        border-bottom: 2px solid #ddd;
    }

    .PaymentTable .table td {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
    }

    .PaymentTable .table td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 10px;
    }
}

.order-container-horizontal {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow-x: auto;
    /* Enable horizontal scrolling */
    overflow-y: hidden;
    /* Hide vertical scrolling */
    white-space: nowrap;
    /* Prevent wrapping of content */
}

.order-container-horizontal>* {
    display: inline-block;
    /* Ensure child elements are inline */
}

.order-group {
    display: inline-flex;
    flex-direction: column;
    margin-right: 10px;
}

.order-date-header {
    padding: 10px;
    margin-bottom: 10px;
}

.order-date-header h5 {
    font-weight: 500;
    margin: 0;
    /* Remove default margin to align text neatly */
    color: #333;
    /* Darker color for better readability */
}

.profile-container {
    width: 100%;
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-header h2 {
    margin-bottom: 20px;
}

.profile-body {
    display: flex;
    flex-direction: column;
}

.profile-info, .form-steps {
    display: flex;
    flex-direction: column;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.form-group input:focus, .form-group textarea:focus {
    border-color: #5c9a41;
    outline: none;
}

.error-message {
    color: red;
    font-size: 12px;
}

.password-group {
    position: relative;
}

.password-group button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
    color: #5c9a41;
}

.form-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.nav-button, .save-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #5c9a41;
    cursor: pointer;
}

.nav-button:disabled {
    background-color: #ccc;
}

.cancel-button {
    background-color: #e74c3c; 
}


.edit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #5c9a41;
    cursor: pointer;
    margin-top: 20px;
}

.custom-modal .modal-content {
    background-color: #f8f9fa;
    border-radius: 10px;
    border: none;
}

.custom-modal .modal-header {
    border-bottom: none;
}

.custom-modal .modal-title {
    color: #5c9a41;
    font-weight: bold;
}

.custom-modal .modal-body {
    padding: 20px;
}

.custom-modal .modal-footer {
    border-top: none;
    padding: 15px;
}

.custom-close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    color: #5c9a41;
    outline: none;
}

.custom-close-btn:hover {
    color: red;
    background-color: transparent;
}

.Modal__Content {
    border: 2px solid red;
    background-color: white;
    z-index: 1000;
    width: 80%;
    height: 80%;
}

.Modal__Overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}