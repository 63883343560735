.schedule-card {
  background: #ECECEC;
  padding: 12px 18px;
  border-radius: 10px;
  min-width: 280px;
  margin-right: 20px;
}

.schedule-card h3 {
  height: 18px;
  font-weight: 500;
  font-size: 24px;
  color: #5C9A41;
}

img.mediumIcon {
  width: 28px;
  object-fit: contain;
  cursor: pointer;
}

.icon-container {
  display: flex;
  align-items: center;
}

ul.schedule-ul {
  padding: 0px;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 10px;
}

ul.schedule-ul strong {
  font-weight: 500;
  font-size: 15px;
  color: #00000080;
}

ul.schedule-ul li {
  display: flex;
  justify-content: space-between;
}

img.smallIcon {
  width: 16px;
  object-fit: contain;
  margin-right: 4px;
}

img.largeIcon {
  width: 36px;
  object-fit: contain;
}

.appointment-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  min-width: 40%;
}

.appointment-card h1 {
  font-size: 16px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 4px;

}

.appointment-card h2 {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}

.appointment-card h2 b {
  color: #000000c7;
}

.green-con {
  background: rgba(126, 217, 87, 0.2);
  border-radius: 6px;
  display: flex;
  padding: 10px;
  align-items: center;
}

.green-con h1 {
  /* Wed, 27 Feb 2024 */

  font-weight: 500;
  font-size: 12px;
  line-height: 10px;

  color: #000000;
}

.appointment-card h4 {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-align: center;
  margin-top: 10px;

}

.order-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.order-card {
  background-color: #5C9A41;
  color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.order-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.order-id {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.order-date-time {
  display: flex;
}

.order-id-icon {
  width: 50px;
  height: 50px;
  background-color: #dff1d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.order-timer {
  display: flex;
  align-items: center;
}

.order-timer-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
}

.expand-order-btn {
  background-color: white;
  color: #5ca941;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

.expand-order-btn:hover {
  background-color: #dff1d7;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  z-index: 1;
  padding: 20px;
  width: 800px !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0px;
}

.medicine-table {
  width: 100%;
  border-collapse: collapse;
}

.medicine-table th,
.medicine-table td {
  border: 1px solid #ddd;
  padding: 8px;
  border: none;
}

.medicine-table th {
  color: #000000B2;
  text-align: center;
}

.medicine-table td {
  text-align: center;
}

.modal-body {
  padding: 0px;
}

.custom-hr {
  border-top: 2px solid #00000033;
  width: 100%;
}

.modal-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  color: #000000a0;
}

.request-accept-btn {
  background-color: #5ca941;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

.request-accept-btn:hover {
  background-color: #4a913a;
}

/* component.css */

.modal-content1 {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
}

.success-modal-content {
  text-align: center;
}

.success-animation video {
  max-width: 400px;
}

.success-message span {
  font-size: 36px;
  margin: 20px 0;
  color: #5C9A41;
  font-weight: 600;
}

.success-message p {
  font-size: 18px;
  margin: 10px 0;
  color: #000000a0;
}

.go-home-btn {
  background-color: #5c9a41;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.go-home-btn:hover {
  background-color: #4a7c32;
}

.OrderConfirm {
  background-color: #FFC1074D;
  padding: 10px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.ongoing-order-card {
  color: black;
  padding: 10px;
  border: 1px solid #0000004A;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.ongoing-order-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.ongoing-order-id {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.ongoing-order-date-time {
  display: flex;
}

.ongoing-order-id-icon {
  width: 50px;
  height: 50px;
  background-color: #dff1d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.view-order-btn {
  background-color: #5ca941;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

.view-order-btn:hover {
  background-color: #4a7c32;
}

.OngoingOrderConfirm{
  background-color: #FFC1074D;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  width: 250px;
}

.ongoing-order-date-time {
  margin-right: auto;
}

/* Base style for status box */
.status-box {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.status-paid {
  background-color: #5c9a415F;
}

.status-unpaid {
  background-color: #dc35455F; 
}

.status-box .fa-icon {
  margin-right: 5px;
  color : #5C9A41
}
.modal.mini-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content.mini-modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* Max width to ensure it doesn't stretch too much */
  height: auto;
}

.modal-header.mini-modal-header {
  margin-bottom: 15px;
}

.modal-body.mini-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-status-btn {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-status-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.prescription-section {
  margin-top: 20px;
}

.prescription-img-container {
  display: flex;
  flex-wrap: wrap; /* Allows images to wrap to the next line if they overflow */
  gap: 10px; /* Adds space between images */
}

.prescription-image {
  width: auto; /* Allows the image width to be determined by its content */
  max-width: 150px; /* Restrict the maximum width of the image */
  max-height: 200px; /* Restrict the maximum height of the image */
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
}

.modal-content1 {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  background: #fff;
  z-index: 2;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.modal-overlay1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}

.modal-close-button1 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}
