body {
  background-color: #fff;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-icon {
  position: relative;
}

.input-icon .fa {
  position: absolute;
  top: 70%;
  left: 10px;
  transform: translateY(-50%);
  color: #6c757d;
}

button {
  background: #5C9A41;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.error {
  color: red;
  font-size: 0.875em;
  margin-top: 0.25em;
  display: block;
}

.forgot-password {
  display: block;
  margin-top: 10px;
  color: #5C9A41;
  cursor: pointer;
}

.forgot-password:hover {
  text-decoration: underline;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.search-bar {
  width: 100%;
  margin-right: 1rem;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  width: 100%;
  padding: 25px;
  font-size: 16px;
  padding-left: 40px;
  border-radius: 4px;
  box-sizing: border-box;
}

.loginform {
  padding: 10px;
  padding-left: 40px;
}

.table-container {
  overflow-x: auto;
}

.table {
  min-width: 1200px;
}

.MuiButton-contained {
  margin-left: 1rem;
}

.MuiTablePagination-root {
  display: flex;
  justify-content: flex-end;
}

/* Ensure full viewport height and width */
.main {
  width: 100%;
  height: 100vh;
  /* Full viewport height */
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
}

.login-container {
  width: 40%;
  /* Left 40% width */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  box-sizing: border-box;
}

.logo {
  width: 150px;
  height: auto;
  margin-bottom: 0.6rem;
}

h2 {
  margin-bottom: 0.6rem;
}

.capsule-selector {
  display: flex;
  width: 60%;
  background-color: #EEEEEE;
  border-radius: 10px;
  position: relative;
  margin-bottom: 1rem;
}

.capsule {
  flex: 1 1;
  text-align: center;
  padding: 0.4rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s, background-color 0.3s;
  position: relative;
  justify-content: center;
}

.capsule.active {
  color: #fff;
  background-color: #5c9a41;
  display: flex;
  margin: 6px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.input-group {
  margin-top: 1rem;
  width: 100%;
  flex-direction: column;
}

.image-label-inline {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

h3 {
  margin: 0;
  font-size: 16px;
  /* Adjust size as needed */
  color: #191919;
  /* Adjust color if needed */
}

.user,
.pass {
  margin-right: 10px;
}

.input-container {
  display: flex;
  justify-content: center;
  width: 80%;
  /* Full width for input */
  margin-bottom: 20px;
}

.input-container input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  /* Full width of the container */
  font-size: 1rem;
  /* Adjust font size as needed */
  box-sizing: border-box;
  /* Include padding in width */
}

.login-button-container{
  width: 80%;
}

.login-button {
  background: #5C9A41;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0px;
  width: 100%;
}

.custom-hr {
  width: 80%;
  /* Full width of the login-container */
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 1rem 0;
}

.link-container {
  width: 80%;
  /* Full width of the login-container */
  text-align: center;
  /* Center the link */
}

.forgot-password {
  color: #5ca949;
  /* Green color for the link */
  text-decoration: none;
  /* Remove underline */
  font-size: 16px;
  /* Adjust font size as needed */
  margin-top: 0px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.login-signup-container{
  position: absolute;
  bottom: 0;
  left: 40%;
  transform: translateX(-50%); 
  padding: 1rem;
  text-align: center;
  width: 70%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.signup-container {
  position: relative;
  bottom: 0;
  padding: 1rem;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.signup-container p {
  margin: 0;
  font-size: 16px;
}

.signup-link {
  color: #5ca949;
  text-decoration: underline;
  font-size: 16px;
}

.signup-link:hover {
  color: #5ca949;
  text-decoration: none;
}

.image-container {
  margin: 2%;
  width: 50%;
  height: 93%;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.login-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .login-container {
    width: 100%;
  }

  .capsule-selector {
    display: flex;
    width: 60%;
    background-color: #EEEEEE;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
  }

  .image-container {
    width: 0%;
    display: none;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 100%;
  }
}

.register-container {
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  box-sizing: border-box;
}

.register-image-container {
  margin: 2%;
  width: 30%;
  height: 93%;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.register-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.register-capsule-selector {
  display: flex;
  width: 30%;
  background-color: #EEEEEE;
  border-radius: 10px;
  position: relative;
  margin-bottom: 1rem;
}

.location-details {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.register-group {
  flex: 0 0 48%;
  margin-bottom: 0.4rem;
}

.register-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.register-group textarea {
  width: 100%;
  border-radius: 8px;
  border-color: #0000004D;
  padding-left: 40px;
}

.register-group input[type="text"],
input[type="password"],
input[type="email"],
select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  padding-left: 40px;
  border-radius: 8px;
  border-color: #0000004D;
  box-sizing: border-box;
  position: relative;
}

@media (max-width: 787px) {
  .register-container {
    width: 100%;
  }

  .register-capsule-selector {
    display: flex;
    width: 60%;
    background-color: #EEEEEE;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
  }

  .register-image-container {
    width: 0%;
    display: none;
  }

  .register-group input[type="text"],
  input[type="password"],
  input[type="email"],
  select {
    padding-left: 5px;
    font-size: small;
  }

  .register-group textarea {
    padding-left: 5px;
    font-size: small;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 100%;
  }
}

.register-button {
  background: none;
  border: none;
  color: #5C9A41;
  cursor: pointer;
}

/* main.css */

/* General form styling */
.btn-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.register-button {
  background-color: #5c9a41;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.register-button:hover {
  background-color: #4a7a31;
  transform: scale(1.05);
}

.register-button:active {
  background-color: #3a5a21;
  transform: scale(0.95);
}

/* Unique next button styling */
.next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.next-button .arrow {
  display: inline-block;
  transition: transform 0.3s ease;
}

.next-button:hover .arrow {
  transform: translateX(5px);
}

.back-button {
  background-color: #d9534f;
}

.back-button:hover {
  background-color: #c9302c;
}

.back-button:active {
  background-color: #ac2925;
}

.register-group input[type="file"] {
  display: none;
  /* Hide the default file input */
}

.custom-file-upload {
  display: inline-block;
  margin-left: 20px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #5c9a41;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #4a7a31;
}

.custom-file-upload:active {
  background-color: #3a5a21;
  transform: scale(0.95);
}

.password-input {
  position: relative;
}

.password-input input {
  width: 100%;
  padding-right: 40px;
}

.password-icon{
  display: flex;
  justify-content: center;
  position: relative;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

/* Container styling */
.input-group {
  margin-bottom: 20px;
}

.image-label-inline {
  display: flex;
  align-items: center;
}

.image-label-inline img {
  margin-right: 10px;
  width: 24px;
  /* Adjust size as needed */
}

.image-label-inline h3 {
  margin: 0;
}

.input-container {
  position: relative;
}

.input-container input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  /* Adjust to make space for the icon */
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.location-picker-button {
  margin-left: 10px;
  background-color: #5c9a41;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.location-picker-button:hover {
  background-color: #4a7a2e;
}

.location-picker-button i {
  margin-right: 8px;
}

.location-address {
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px; 
  margin-left: auto; 
  margin-right: auto;
}

.location-address p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Modal Container */
.Modal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.Modal__Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Modal Title */
.Modal__Title {
  margin-top: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

/* Close Button */
.Modal__CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.Modal__CloseButton:hover {
  color: #e53935;
}

/* Optional: For the button inside the modal */
.Modal__ConfirmButton {
  padding: 10px 20px;
  background-color: #5c9a41;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.Modal__ConfirmButton:hover {
  background-color: #4a7b32;
}

.container9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
}

.message {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.check-status-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #5c9a41;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.check-status-button:hover {
  background-color: #5c9a41b9;
}

.modal-content2 {
  padding: 20px;
  background: white;
  border-radius: 8px;
  max-width: 500px;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.time-picker-container {
  margin-bottom: 16px;
}

@media (max-width: 480px) {
  .login-container {
    width: 100%;
  }

  .capsule-selector {
    width: 100%;
  }

  .register-capsule-selector {
    width: 100%;
  }

  .input-container {
    width: 100%;
  }

  .login-button-container{
    width: 100%;
  }

  .login-button{
    width: 100%;
  }

  .link-container{
    width: 100%;
  }

  .signup-container{
    width: 80%;
  }

  .register-container {
    width: 100%;
  }

  .form-row{
    display: inline;
  }

  .login-signup-container{
    width: 100%;
  }
  
}

.phone-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.verify-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #5c9a41;
  color: white;
  border: none;
  padding: 4px 10px;
  cursor: pointer;
  border-radius: 8px;
  height: 80%;
}

.verified-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #28a745;
  font-size: 16px;
}

.otp-modal-content {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  padding: 30px;
  outline: 0;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.otp-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.location-input-container {
  position: relative;
}

.location-input-container input {
  width: calc(100% - 40px); /* Adjust width to make room for the button */
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #0000004D;
  box-sizing: border-box;
}

.location-picker-button1 {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 8%;
  background-color: #5c9a41;
  border: none;
  color: #fff;
  border-radius: 0 8px 8px 0;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-picker-button1 i {
  font-size: 16px;
}

.location-picker-button1:hover {
  background-color: #4a7d2c;
}

/* Custom Button Style for Pending Status */
.swal-button-pending {
  background-color: #4cbba1; /* Amber */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.swal-button-pending:hover {
  background-color: #4cbba1; /* Darker Amber */
}

/* Custom Button Style for Approved Status */
.swal-button-approve {
  background-color: #5c9a41; /* Green */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.swal-button-approve:hover {
  background-color: #5c9a41; /* Darker Green */
}

/* Custom Button Style for Rejected Status */
.swal-button-reject {
  background-color: #dc3545; /* Red */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.swal-button-reject:hover {
  background-color: #c82333; /* Darker Red */
}

/* Custom Button Style for Unexpected Status */
.swal-button-unexpected {
  background-color: #6c757d; /* Gray */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.swal-button-unexpected:hover {
  background-color: #5a6268; /* Darker Gray */
}

/* Custom Button Style for Failed Status */
.swal-button-failed {
  background-color: #17a2b8; /* Teal */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.swal-button-failed:hover {
  background-color: #138496; /* Darker Teal */
}

/* Custom Button Style for Error */
.swal-button-error {
  background-color: #dc3545; /* Red */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.swal-button-error:hover {
  background-color: #c82333; /* Darker Red */
}
