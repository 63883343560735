.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
}

.modal-content {
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 100%;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
    color: #dc3545;
}

.date-picker-container {
    position: relative;
}

.date-picker {
    width: calc(100% - 40px);
    padding-right: 40px;
}

.calendar-icon {
    position: absolute;
    top: 44%;
    right: 20px; /* Adjust position to fit within the border */
    transform: translateY(-50%);
    width: 24px;
    height: auto;
    cursor: pointer;
}

.dropdown-checklist {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    margin-top: 5px;
}

.dropdown-checklist div {
    display: flex;
    padding: 5px 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: baseline;
}

.dropdown-checklist .btn-primary {
    background-color: #5ca941;
    border-color: #5ca941;
    margin-top: 10px;
}

.btn-outline-secondary {
    background-color: #f8f9fa;
    border-color: #5ca941;
    color: #5ca941;
}

.btn-outline-secondary:hover {
    background-color: #5ca941;
    color: #fff;
}

.list-group-item {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
}

/* Container for each checkbox and label */
.dropdown-checklist .d-flex {
    align-items: center;
    margin-bottom: 0.5rem; /* Adjust spacing between items */
}

/* Styles for the custom checkbox */
.checkbox-container {
    position: relative;
    display: inline-block;
    width: 24px; /* Adjust width as needed */
    height: 24px; /* Adjust height as needed */
}

/* Hide default checkbox */
.checkbox-container input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

/* Custom checkbox appearance */
.checkbox-container .custom-checkbox {
    position: relative;
    width: 24px; /* Match width with container */
    height: 24px; /* Match height with container */
    background-color: #fff; /* Background color for unselected state */
    border: 2px solid #5ca941; /* Border color */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s, border-color 0.3s;
}

/* Checked state styling */
.checkbox-container input[type="checkbox"]:checked + .custom-checkbox {
    background-color: #5ca941; /* Checkbox background color when checked */
    border-color: #5ca941; /* Border color when checked */
}

/* Checkmark styling */
.checkbox-container .custom-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input[type="checkbox"]:checked + .custom-checkbox:after {
    display: block;
    left: 8px; /* Adjust left position */
    top: 4px; /* Adjust top position */
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Label next to checkbox */
.checkbox-label {
    margin-left: 8px; /* Adjust spacing between checkbox and label */
    font-size: 14px; /* Adjust font size as needed */
}
